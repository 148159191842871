<template>
	<v-card>
		<v-toolbar dense color="primary">
			<v-toolbar-title class="white--text">Cambio de estado</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-btn icon color="white" @click="$emit('close')">
				<v-icon>mdi-close</v-icon>
			</v-btn>
		</v-toolbar>

		<div class="pa-4">
			<span class="text-h6">Contratos: {{ contratos.join(", ") }}</span>

			<v-list dense>
				<v-list-item
					@click="nuevoEstado = estado.TextoSituacion"
					v-for="estado in estados"
					:key="estado.IdContratoSituacion"
				>
					<status-chip :value="estado.TextoSituacion"></status-chip>
				</v-list-item>
			</v-list>
			<template v-if="nuevoEstado">
				<v-divider></v-divider>
				<div class="d-flex align-center">
					<v-card-title> Cambiar estado a : </v-card-title>
					<status-chip :key="nuevoEstado" :value="nuevoEstado"></status-chip>
					<v-spacer></v-spacer>
					<v-btn @click.stop="cambiarEstado" color="primary" outlined
						>Aceptar</v-btn
					>
				</div>
			</template>
		</div>
	</v-card>
</template>

<script>
import { processToken } from "@/utils/index.js";

export default {
	components: {
		StatusChip: () => import("@/components/StatusChip.vue"),
	},
	props: {
		contratos: { type: Array },
	},
	data() {
		return {
			nuevoEstado: null,
			estados: [],
		};
	},
	async mounted() {
		let str = this.$store.getters.getDatosEmpresa;
		const { IdUsuario, IdAgente } = str.iss;
		const IdEmpresa = this.$store.getters.getIdEmpresa
		try {
			var { data: estados } = await axios({
				method: "get",
				url: `${process.env.VUE_APP_API_URL}/Contrato/GetDTOAllContratosSituaciones/Empresa/${IdEmpresa}/Usuario/${IdUsuario}/Agente/${IdAgente}`,
			});
			this.estados = processToken(estados).iss;
		} catch (e) {
			console.log(e);
			this.$root.$emit("snack", "Ha ocurrido un error");
		}
	},
	methods: {
		async cambiarEstado() {
			if (!confirm('¿Estás seguro de que quieres cambiar estos contratos a '+this.nuevoEstado+'?')) return;
			await axios({
				method: "POST",
				url: `${process.env.VUE_APP_OUR_API_URL}/cambiarEstado.php`,
				data: {
					token: this.$store.getters.getJwtEmpresa,
					contratos: this.contratos.map(x => this.$store.getters.getEntorno == 'Luz' ? Math.abs(x) : Math.abs(x) * -1),
					estadoContrato: this.nuevoEstado,
				},
			})
				.then((res) => {
					this.$root.$emit("snack", "Se ha cambiado el estado con éxito");
					this.cambioEstado = false;
					this.$emit('close');
					this.$emit('reload');
				})
				.catch((err) => {
					console.error(err);
					this.$root.$emit("snack", "No se ha podido cambiar el estado");
				});
		},
	},
};
</script>

<style>
</style>